import * as React from "react";
import { Helmet } from "react-helmet";
import LayoutPrimary from "../templates/LayoutPrimary";
import { galleryPageContainer } from "../styles/components/galleries.module.scss";

import "../styles/components/content.module.scss";

import BuyBtn from "../components/buttons/BuyBtn";
import { serviceInfoBtns } from "../styles/components/blocks.module.scss";
import OurBoatGallary1 from "../components/galleries/ourBoatGallary1";
import OurBoatGallary2 from "../components/galleries/outBoadGallary2";
import OurBoatGallary3 from "../components/galleries/outBoadGallary3";

import OurBoatGallary4 from "../components/galleries/outBoadGallary4";

import { Link } from "gatsby";

import { btn } from "../styles/components/buttons.module.scss";
const GalleryPage = () => {
  return (
    <LayoutPrimary>
      <Helmet>
        <title>
          Party Boat Charter Our Boat | Tampa Bay Boating Adventures
        </title>
        <meta
          name="description"
          content="See photos of our private party boat charter trips along the Tampa, Florida coastline and book yours online today!"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="container">
        <div className="row center this-pad-y-4-lg this-pad-y-2">
          <div className="col-xxs-12">
            <div className={galleryPageContainer}>
              <h1> Our Boat</h1>
              <h2>
                {" "}
                Explore Our Party Boat - Adventure I 26’ Bennington Deck Boat
              </h2>
              <h3>
                Tampa’s Premier Party Boat Offers a Massive Sound System, Space
                for Lounging, Color-Changing Lights, and Much More
              </h3>

              <div className="this-pad-y-2">
                <OurBoatGallary1 />
              </div>
              <div
                className={serviceInfoBtns}
                style={{
                  display: "flex",
                  textAlign: "center",

                  justifyContent: "flex-start",
                }}
              >
                <BuyBtn />

                <Link
                  to="tel:8137428178"
                  className={`${btn}`}
                  style={{ marginRight: "10px", background: "#0DB0E7" }}
                >
                  Call (813) 742-8178
                </Link>

                {/* <a href="#description" className={btn} onClick={toggleClass}>{isActive ? "Hide Description" : "View Description"}</a> */}
              </div>
              <p>
                {" "}
                Welcome to Adventure I, our 26-foot party boat designed for your
                ultimate Tampa Bay Boating Adventure! Whether you’re hosting a
                party, celebrating a special occasion, or just looking for a day
                of fun on the water, our boat is equipped with everything you
                need to have a blast. Here's what makes our boat special:
              </p>
              <div className="this-pad-y-2">
                <OurBoatGallary2 />
              </div>
              <div
                className={serviceInfoBtns}
                style={{
                  display: "flex",
                  textAlign: "center",

                  justifyContent: "flex-start",
                }}
              >
                <BuyBtn />

                <Link
                  to="tel:8137428178"
                  className={`${btn}`}
                  style={{ marginRight: "10px", background: "#0DB0E7" }}
                >
                  Call (813) 742-8178
                </Link>

                {/* <a href="#description" className={btn} onClick={toggleClass}>{isActive ? "Hide Description" : "View Description"}</a> */}
              </div>
              <h2>Comfort and Space</h2>
              <p>
                Say goodbye to crowded and uncomfortable boat trips. Adventure I
                is spacious enough to hold up to 12 guests comfortably, with
                plenty of room to move around. Our premium two-tone faux leather
                seats provide ample seating and lounging space, while the
                elevated tanning platform at the back is perfect for soaking up
                the sun. No more bumping knees or fighting for a spot.
              </p>
              <div className="this-pad-y-2">
                <OurBoatGallary3 />
              </div>
              <div
                className={serviceInfoBtns}
                style={{
                  display: "flex",
                  textAlign: "center",

                  justifyContent: "flex-start",
                }}
              >
                <BuyBtn />

                <Link
                  to="tel:8137428178"
                  className={`${btn}`}
                  style={{ marginRight: "10px", background: "#0DB0E7" }}
                >
                  Call (813) 742-8178
                </Link>

                {/* <a href="#description" className={btn} onClick={toggleClass}>{isActive ? "Hide Description" : "View Description"}</a> */}
              </div>
              <h2>Top-Notch Entertainment</h2>
              <p>
                {" "}
                A party isn't a party without great music, and we've got you
                covered. Adventure I features a state-of-the-art sound system
                with color-changing speakers and boat lights to keep the energy
                high all day long. Whether you're into chill tunes or party
                anthems, our sound system will keep the good vibes flowing.
              </p>
              <h2>Plenty of Amenities</h2>
              <p>
                We’ve thought of everything to ensure you have a smooth and
                seamless trip. With an abundance of coolers, cupholders, and
                storage space, you won't have to worry about where to keep your
                drinks or belongings. We also offer more seats than you'll ever
                need, so there's always space for everyone to relax and enjoy
                the ride.
              </p>
              <h2>Optional Party Packages</h2>
              <p>
                {" "}
                Want to take your party to the next level? We offer a range of
                optional packages to customize your experience. Add a personal
                photographer to capture all your best moments, a professional DJ
                to keep the music going, or a personal bottle girl/boy to serve
                drinks and ensure everyone is having a great time. These options
                can be included in any package, making it easy to create the
                perfect party atmosphere.
              </p>
              <div className="this-pad-y-2">
                <OurBoatGallary4 />
              </div>
              <div
                className={serviceInfoBtns}
                style={{
                  display: "flex",
                  textAlign: "center",

                  justifyContent: "flex-start",
                }}
              >
                <BuyBtn />

                <Link
                  to="tel:8137428178"
                  className={`${btn}`}
                  style={{ marginRight: "10px", background: "#0DB0E7" }}
                >
                  Call (813) 742-8178
                </Link>

                {/* <a href="#description" className={btn} onClick={toggleClass}>{isActive ? "Hide Description" : "View Description"}</a> */}
              </div>
              <h2> Ready to Book?</h2>
              <p>
                Join us on Tampa's most comfortable party boat for a day you'll
                never forget. Our team has spent countless hours perfecting
                every detail, and we're excited to share it with you. Book now
                to secure your spot on Adventure I, and get ready for the
                ultimate boating experience!
              </p>
              <p>
                For more information or to make a reservation, contact us today.
                We can't wait to see you on the water!
              </p>
            </div>
          </div>
        </div>
      </main>
    </LayoutPrimary>
  );
};

export default GalleryPage;
