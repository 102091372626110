import * as React from "react";
import { useState } from "react";
import { galleryFullContainer } from "../../styles/components/galleries.module.scss";
import FsLightbox from "fslightbox-react";

const OurBoatGallary2 = () => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  return (
    <div className={galleryFullContainer}>
      <div className="row stretch pad-all-1-sm pad-y-1">
        <div className="col-sm-4 col-xs-6 col-xxs-12">
          <button onClick={() => openLightboxOnSlide(1)}>
            <img
              style={{ minHeight: "244px" }}
              src="https://tampabayboatingadventures.com/2006-honda-225-motor.jpg"
              alt="Description"
            />
          </button>
        </div>
        <div className="col-sm-4 col-xs-6 col-xxs-12">
          <button onClick={() => openLightboxOnSlide(2)}>
            <img
              style={{ minHeight: "244px" }}
              src="https://tampabayboatingadventures.com/dancing-bennington-deck-boat-party.jpg"
              alt="Description"
            />
          </button>
        </div>
        <div className="col-sm-4 col-xs-6 col-xxs-12">
          <button onClick={() => openLightboxOnSlide(3)}>
            <img
              style={{ minHeight: "244px" }}
              src="https://tampabayboatingadventures.com/downtown-tampa-DJs-party-boat.jpg"
              alt="Description"
            />
          </button>
        </div>
 
      </div>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={[
          "https://tampabayboatingadventures.com/2006-honda-225-motor.jpg",
          "https://tampabayboatingadventures.com/dancing-bennington-deck-boat-party.jpg",
          "https://tampabayboatingadventures.com/downtown-tampa-DJs-party-boat.jpg",
         
        ]}
        type="image"
        slide={lightboxController.slide}
      />
    </div>
  );
};

export default OurBoatGallary2;
